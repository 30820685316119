import { memo } from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppRouting from './AppRouting'

import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'

import './AppView.scss'

const AppView = (props: App.DefaultProps): JSX.Element => {
    return(
        <app-view class='app'>
            <BrowserRouter>
                <Header />
                    <AppRouting />
                <Footer />
            </BrowserRouter>
        </app-view>
    )
}

export default memo(AppView)