import { memo } from 'react'

import './PortfolioView.scss'

const PortfolioView = (props: App.DefaultProps) => {
    return(
        <app-view class='portfolio'>

        </app-view>
    )
}

export default memo(PortfolioView)