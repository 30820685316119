import { useLocation } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'

import './Header.scss'

export const Header = () => {

    const menu: Record<string, string> = {
        'Home': '/',
        'Our Portfolio': '/portfolio',
        'About us': '/about-us'
    }

    return(
        <header>
            <div className='header-content'>
                <div className='logo'>
                    <Link to='/'>
                        <div className='logo-image' />
                    </Link>
                </div>

                <app-menu>
                    <ul>
                        {Object.entries(menu).map(([key, value], i) => (
                            <MenuItem key={i} to={value}>{key}</MenuItem>
                        ))}
                    </ul>
                </app-menu>
            </div>
        </header>
    )
}

const MenuItem = (props: App.DefaultProps & LinkProps): JSX.Element => {
    const location = useLocation()
    const current = location.pathname == props.to ? 'current' : undefined

    return(
        <li className={current}>
            <Link to={props.to}>
                <span>{props.children}</span>
            </Link>
        </li>
    )
}