import './Footer.scss'

export const Footer = () => (
    <footer>
        <div className='footer-content'>
            <div className='copyright'>
                &copy; {new Date().getFullYear()} Appkwekerij
            </div>
            <div className='flex-spacer' />
        </div>
    </footer>
)