import { memo } from 'react'
import { Link } from 'react-router-dom'

import './HomeView.scss'

const HomeView = (props: App.DefaultProps) => {
    return(
        <app-view class='home'>
            <div className='background'>
                <div className='background-container'>
                    <div className='content-container'>
                        <h1>We are giving our site a new look.</h1>
                        <p>Don't be afraid though! You can still look around and browse through our <Link to='/portfolio'>portfolio</Link> or <Link to='/about-us'>contact us</Link>.</p>
                    </div>
                    <div className='illustration' />
                </div>
            </div>
        </app-view>
    )
}

export default memo(HomeView)