import { memo } from 'react'
import { RoutesProps, Routes, Route } from 'react-router-dom'

import HomeView from '../home/HomeView'
import AboutView from '../about-us/AboutView'
import PortfolioView from '../portfolio/PortfolioView'

import { StatusCodeView } from '../../components/StatusCodeView/StatusCodeView'

const AppRouting = (props: App.DefaultProps & RoutesProps) => {
    return(
        <div className='transition'>
            {props.children}
            <app-component class='view-holder'>
                <Routes>
                    <Route path='/' element={<HomeView />} />
                    <Route path='/portfolio' element={<PortfolioView />} />
                    <Route path='/about-us' element={<AboutView />} />

                    <Route path='*' element={<StatusCodeView code={404} />} />
                </Routes>
            </app-component>
        </div>
    )
}

export default memo(AppRouting)