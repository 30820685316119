import './StatusCodeView.scss'

interface StatusCodeViewProps extends App.DefaultProps {
    code: number
}

export const StatusCodeView = (props: StatusCodeViewProps) => {
    return(
        <app-view class='error status-code'>
            <p>Error: {props.code}</p>
        </app-view>
    )
}